import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'es6-promise/auto'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


const TWO_HOURS = 2 * 60 * 60 * 1000

const state = {
    loaded: false,
    expiryDate: new Date,
    properties: [],
    press: [],
    currentFilterOption:  {label: 'Available', value: 'available'},
}

// getters
const getters = {
    checkExpiryDate: (state) => {
        return ((new Date) - state.expiryDate) > TWO_HOURS;
    },
    getLoaded: (state) => {
        return state.loaded;
    },
    properties: (state) => {
        return state.properties;
    },
    press:() => {
        return state.press;
    },
    currentFilterOption: (state) => {
        return state.currentFilterOption;
    },
    pressLoaded:() => {
        return state.press.length > 0;
    }
}

// actions
const actions = {
    increment (context) {
        context.commit('increment')
    },
    getProperties (context){
        context.commit('getProperties')
    },
    propertiesLoaded(context){
        context.commit('propertiesLoaded')
    },
    updateCurrentTime(context){
        context.commit('updateCurrentTime')
    },
    updateProperties(context){
        context.commit('getProperties');
        context.commit('updateCurrentTime')
    },
    updateFilterOption ({ commit }, newOption) {
        commit('setFilter', newOption)
    },
    getPress(context){
        context.commit('getPress')
    },
}

// mutations
const mutations = {
    getProperties (state){
        axios.get('https://strapi.uptowncreative.io/paul-salazar-properties?_sort=Price:DESC,Tag:ASC')
        .then((response) => {
            state.properties = response.data
        })
    },
    propertiesLoaded (state){
        state.loaded = true;
    },
    updateCurrentTime (state){
        state.expiryDate = new Date;
    },
    setFilter (state, newOption){
        state.currentFilterOption = newOption;
    },
    getPress (state){
        axios.get('https://strapi.uptowncreative.io/paul-salazar-presses?_sort=DisplayOrder:ASC')
        .then((response) => {
            state.press = response.data
            state.pressLoaded = true;
        })
    },
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})