<template>
    <nav role="navigation" v-bind:class="{ topMenuShow: topMenuShow}">
        <div class="desktop_menu">
          <router-link to="/">
            <img class="logo" src="/images/paul_salazar.png" alt="Paul Salazar">
          </router-link>
          <div class="menuLinks">
            <router-link v-for="item in menuItems" :key="item.label" :to="item.link" @click.native="linkClick">
              {{ item.label }}
            </router-link>
          </div>
        </div>
        <div class="mobile_menu">
          <router-link to="/">
            <span id="mobile_logo" alt="Paul Salazar" />
          </router-link>
          <transition name="fade">
          <div id="menu" v-if="menuShow"> 
              <ul>
                <li v-for="item in menuItems" :key="item.label" >
                  <router-link :to="item.link" @click.native="linkClick">
                    {{ item.label }}
                  </router-link>
                </li>
              </ul>
              <FooterSocialMedia />
          </div>
          </transition>
          <div id="menuToggle" >
              <input type="checkbox" v-model="menuShow"/>
              <span></span>
              <span></span>
              <span></span>
          </div>
        </div>
    </nav>
    
</template>

<script>
import FooterSocialMedia from "../Footer/FooterSocialMedia";

export default {
    name: 'Menu',
    components: {FooterSocialMedia},
    data(){
        return{ 
          menuItems: [
              {
                  label: "Properties",
                  link: "/properties"
              },
              {
                  label: "Sold",
                  link: "/properties/sold"
              },
              {
                  label: "Press",
                  link: "/press"
              },
              {
                  label: "Web Series",
                  link: "/webseries"
              },
              {
                  label: "Expired to Sold",
                  link: "/expiredtosold"
              },
              {
                  label: "Testimonials",
                  link: "/about#testimonial"
              },
              {
                  label: "About",
                  link: "/about"
              },
              {
                  label: "Team",
                  link: "/team"
              },
              {
                  label: "Contact",
                  link: "/contact"
              },
          ],
          menuShow: false,
          topMenuShow: false,
      }
    },
    methods: {
      linkClick(){
        this.menuShow = false;
      },
      handleScroll(){
        if (window.scrollY > 170){
          this.topMenuShow = true;
        }else if(window.scrollY < 20){
          this.topMenuShow = false;
        }
      },
    },
    watch:{
      $route (){
        this.menuShow = false;
      }
    },
    created: function(){
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
</script>

<style lang="scss" scoped>
nav {
  position: relative;
  width: 100%;
  top: 0;
  height:60px;
  z-index: 10;
  a {
    &:visited{
      color: #fff;
    }
    color: #fff;
    text-decoration: none;
    &:hover{
      opacity: 0.8;
    }
  }
  .logo{
    position: absolute;
    width: 180px;
    height: auto;
    top: 21px;
    left: 4rem;
  }
  #mobile_logo{
    position: absolute;
    width: 169.5px;
    height: 30px;
    background-size: 169.5px 30px;
    background-image: url("/images/ps-mobile.png");
    top: 15px;
    left: 20px;
  }
  .menuLinks{
    padding-top: 42px;
    padding-right: 28px;
    color: #fff;
    text-transform: uppercase;
    text-align: right;
    a{
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 2px;
      padding-right: 2rem;
      opacity: 0.6;
      &:hover{
        opacity: 1;
      }
    }
  }
}

.mobile_menu{
  display: none;
  background: black;
}

.topMenuShow{
  background: black;
  position: sticky;
  .mobile_menu{
    display: block;
  }
  .desktop_menu{
    display: none;
  }
}


#menuToggle
{
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 20;
  -webkit-user-select: none;
  user-select: none;
  & input {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -3px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    
    -webkit-touch-callout: none;
  }
  & span {
    margin-top: 5px;
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 7px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2){
      transform-origin: 0% 100%;
    }
  }
  input:checked ~ span{
    opacity: 0.8;
    transform: rotate(45deg) translate(-8px, -15px);
    background: #fff;
    width: 30px;
    &:nth-last-child(3){
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    &:nth-last-child(2){
      transform: rotate(-45deg) translate(-4px, 11px);
      width: 30px;
    }
  }
}

#menu
{
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  display: block;
  background-color:rgba(0, 0, 0, 0.9);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  z-index: 10;
  ul{
    list-style-type: none;
    margin: 0 auto;
    margin-top: 40px;
    padding-inline-start: unset;
  }
  ul>li {
    color:#fff;
    padding: 0.75rem 0;
    font-size: 1.75rem;
    &:hover{
      opacity: 0.85;
    }
    a{
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 200;
      &:hover{
        color: #B89C45;
      }
    }
  }
}

@media (max-width: 1210px) {
  .desktop_menu{
    display: none;
  }
  .mobile_menu{
    display: block;
      #menu ul{
          padding-bottom: 1.5rem;
      }
  }
  nav{
    background: black;
    position: sticky;
  }
}
</style>
