import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import vmodal from 'vue-js-modal'

//router
import VueRouter from 'vue-router'
//Meta
import Meta from 'vue-meta';

import * as VueGoogleMaps from 'vue2-google-maps'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueGtag from "vue-gtag";
import Toast from "vue-toastification";
import { vfmPlugin } from 'vue-final-modal'

//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import "./filters"
//import GmapCluster from 'vue2-google-maps/dist/components/cluster'

import VueLazyload from 'vue-lazyload'

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(vmodal)

Vue.use(VueGtag, {
  config: { id: "AW-553436788" }
});

library.add(faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faPhoneAlt, faEnvelope, faTimes, faYoutubeSquare, faChevronDown, faChevronUp)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)

Vue.use(VueLazyload)
Vue.use(Toast);
Vue.use(vfmPlugin);

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'images/loading.gif',
  attempt: 1
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCxCk5w2UmLBdAFuME2cC2lJZfyZ9K1H1Y',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

//Vue.component('GmapCluster', GmapCluster)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home') },
    { path: '/about', component: lazyLoad('About')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/thankyou', component: lazyLoad('ThankYou')},
    { path: '/contact', component: lazyLoad('Contact')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/press', component: lazyLoad('Press')},
    { path: '/webseries', component: lazyLoad('WebSeries')},
    { path: '/expiredtosold', component: lazyLoad('ExpiredToSold')},
    { path: '/properties/:category?', component: lazyLoad('Properties')},
    { path: '/property/:slug', component: lazyLoad('Property')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
        return { selector: to.hash }
    } else {
        return { x: 0, y: 0 }
    }
  },
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
