<template>
    <div class="floating-icons">
        <div class="icons-list" >
            <a v-for="item in socialMediaItems" :key="item.icon" v-bind:href="item.url" target="_blank">
                <font-awesome-icon :icon="['fab', `${item.icon}`]" class="icon" :class="{blackIcons: turnBlack}"/>
            </a>
        </div>
    </div>
</template>

<script>

function elementInViewport(el) {
  var top = el.offsetTop;
  var height = el.offsetHeight;

  while(el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
  }
  return (
    window.pageYOffset + 230 >= top &&
    (top + height) >= (window.pageYOffset + window.innerHeight - 360)
  );
}

export default {
    name: "FollowUs",
    created() {
        document.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const el = document.getElementsByClassName('whitebg')[0]
            if (el !== undefined){
                if(elementInViewport(el)) {
                    this.turnBlack = true;
                }else{
                    this.turnBlack = false;
                }
            }
        }
    },
    data(){
        return{
            turnBlack: false,
            socialMediaItems: [
                {
                    icon: 'instagram',
                    url:"https://www.instagram.com/paulsalazargroup/",
                },
                {
                    icon: 'linkedin',
                    url:"https://www.linkedin.com/in/paulsalazarhh/",
                },
                {
                    icon: 'facebook-square',
                    url:"https://www.facebook.com/PaulSalazarRealtor/",
                },
                {
                    icon: 'youtube-square',
                    url:"https://www.youtube.com/channel/UCouvftJFvnk53DzTHAxvBLA",
                },
                
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.floating-icons{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 20px;
    z-index: 3;
    color: #e3e3e3;
    span {
        display: block;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        white-space: nowrap;
        position: relative;
        width: 85px;
        transform: rotate(-90deg) translate(-100%, 15px);
        transform-origin: bottom left;
        transition: all .3s ease;
        &.blackFont{
            color:#000;
        }
    }
}
.socialLinks{
    display: flex;
    justify-content: center;
}
.icon{
    color: #e3e3e3;
    margin-bottom: 0.5rem;
    &:hover{
        opacity: 0.85;
    }
    &.blackIcons{
        color: #000;
    }
}
@media (max-width: $tablet) {
    .floating-icons{
        display: none;
    }
}
</style>
