<template>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
        <div class="mail-container">
            <button class="modal__close" @click="showModal = false">
                <img src="/images/close.svg" alt="Close icon">
            </button>
            <div id="mc_embed_signup">
                <h1>
                    Elevate your inbox
                </h1>
                <p>Subscribe to our newsletter for curated high-end real estate trends and opportunities.</p>
                <form>
                    <div class="form-submission">
                        <div class="input-field">
                            <input type="email" name="email" id="email" placeholder="email@address.com" v-model="email">
                            <button class="submit-button" :disabled="!checked" type="button" @click="subscribe">Subscribe</button>
                        </div>
                        <div class="checkbox-field">
                            <input type="checkbox" id="agreement" name="agreement" v-model="checked">
                            <label for="agreement"> I would like to receive promotional emails.</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
  name: 'MailSignupPopup',
  data: () => ({
    showModal: false,
    checked: false,
    email: '',
  }),
  mounted:function(){
      setTimeout(()=> {
          this.showModal = true
      }, 20000);
  },
  methods:{
    subscribe(){
        this.axios({
            method: 'post',
            url: `https://api.sierrainteractivedev.com/leads`,
            data: {
                firstName: "No",
                lastName: "Name",
                password: '3104920737',
                email: this.email
            },
            headers: {
                'Sierra-ApiKey': '1f24396c-ae08-493f-9c4e-7770a5dced44'
            }
        })
        .then(
            () =>{
                this.$toast.success("Thank you for subscription", {
                    position: "top-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            this.showModal = false;
        })
        .catch(
            () => {
                this.$toast.error("Error submitting, please try again.", {
                    position: "top-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }
        )
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    width: 45%;
    min-width: 450px;
    max-width: 600px;
}
.modal__close {
  position: absolute;
  top: 16px;
  right: 8px;
}

.modal__close img{
    width: 30px;
}

button{
    background: transparent;
    border: none;
    width: unset;
    &:hover{
        cursor: pointer;
    }
}

.mail-container{
    position: relative;
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px 0 40px;
	background-color: #bf9b30;
    border-radius: 15px;
}

.mail-container h1{
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
}

.mail-container p{
	font-weight: 400;
    color: #000;
    font-size: 16px;
    opacity: 1;
	text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
}

.mail-container label{
    letter-spacing: 1px;
	margin-left: 5px;
}

.mail-container #mc_embed_signup {
	min-width: 320px;
	width: 80%;
    max-width: 500px;
}

.form-submission{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.input-field{
    min-width: 300px;
    width: 100%;
    position: relative;
    margin-top: 20px;
    input[type=email]{
        width: 100%;
        height: 50px;
        font-size: 15px;
        letter-spacing: 2px;
        padding-left: 10px;
        color: #000;
        &::placeholder{
            color: #999;
        }
    }
    .submit-button{
        display: block;
        position: absolute;
        right: 10px;
        top: 7px;
        padding: 10px 10px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
        -webkit-appearance:none;
        border: 1px solid #ccc;
        &:enabled{
            color: #fff;
            background: #bf9b30;
            border: 1px solid #bf9b30;
            &:hover{
                cursor: pointer;
            }
        }
        &:disabled{
            &:hover{
                cursor: not-allowed;
            } 
        }
    }
}
.checkbox-field{
    min-width: 300px;
    width: 100%;
    margin-top: 20px;
    input {
        margin: 0;
        width: 16px;
        height: 16px;
    }
    label{
        vertical-align: text-top;
        font-size: 14px;
        letter-spacing: 1px;
    }
}

@media (max-width: $mobile) { 
    ::v-deep .modal-content {
        width: 45%;
        min-width: 320px;
    }
    .modal__close img{
        width: 20px;
    }
    .mail-container #mc_embed_signup {
        min-width: 300px;
        width: 80%;
    }
    #mc-embedded-subscribe{
        right: 20px !important;
    }
    .input-field{
        max-width: 400px;
        input[type=email]{
            font-size: 15px;
            letter-spacing: 1px;
            padding-left: 5px;
            color: #000;
            &::placeholder{
                color: #999;
            }
        }
        .submit-button{
            display: block;
            position: absolute;
            right: 5px;
            top: 7px;
            padding: 10px 5px;
            letter-spacing: 1px;
        }
    }
    .checkbox-field{
        max-width: 400px;
        margin-top: 20px;
        input {
            margin: 0;
            width: 16px;
            height: 16px;
        }
        label{
            vertical-align: text-top;
            font-size: 15px;
            letter-spacing: 1px;
            opacity: 0.5;
        }
    }
    .subscribe-inside{
        width: 100%;
    }
}
</style>

