<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer> </Footer>
    <MailSignupPopup />
    <FollowUs></FollowUs>
  </div>
</template>

<script>
import lazyLoadComponent from './utils/lazy-load-component';
import SkeletonBox from './components/SkeletonBox';
import Header from './components/Header'
import FollowUs from './components/FollowUs'
import MailSignupPopup from './components/MailSignupPopup';

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
  name: 'app',
  components: {
    Header,
    FollowUs,
    MailSignupPopup,
    Footer: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./components/Footer.vue`),
    }),
  },
}

import 'vueperslides/dist/vueperslides.css';
import "vue-toastification/dist/index.css";
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;400;500;600;700&display=swap');
@import url('https://use.typekit.net/qnl3ywo.css');
html, body{
  font-family: 'Catamaran', sans-serif;
  position: relative;
  margin:0;
  padding:0;
  height: 100%;
  min-height: 100vh;
  width:100%;
  background: #000;
  letter-spacing: 2px;
}

*{
  box-sizing: border-box;
}

p{
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 21px;
  opacity: 0.5;
  color: #fff;
}
a{
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
}

button:focus{
  outline: none;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}

.gm-style-iw{
  width: 200px;
  button{
    display: none !important;
  }
  &-c{
    padding: 0 !important;
  }
  &-d{
    max-width: unset !important;
    overflow: auto !important;
  }
}

.tag{
  background: #bf9b30;
  color: #fff;
  padding: 5px 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  &.sold{
    background: #fff;
  }
}
</style>